import { createEvent, sample } from 'effector'
import * as error from './error-map'
import * as i18n from './i18n'

export const init = createEvent()

sample({
  clock: init,
  target: [i18n.init, error.init],
})
