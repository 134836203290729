import type { TFunction } from '!/i18n'
import type {
  TVShowsPlaylist,
  TvShowEpisode,
  TvShowSeasonWithEpisodes,
} from '@setplex/tria-api'
import type { AdjustPurchaseableFn } from '~/features/payment/sse-purchases'
import {
  extendMediaItemByLabelPriceTvShowEpisode,
  extendMediaItemByLabelPriceTvShowSeason,
} from '~/shared/tools'

const formatSeasonEpisode = ({
  episode,
  t,
  adjustEpisode,
}: {
  episode: TvShowEpisode
  t: TFunction
  adjustEpisode: AdjustPurchaseableFn
}) => {
  const adjustedEpisode = adjustEpisode(episode)
  const { labelPrice, rented, displayNumber } =
    extendMediaItemByLabelPriceTvShowEpisode(adjustedEpisode)

  return {
    ...adjustedEpisode,
    title: t('episode_number', { number: displayNumber }),
    labelPrice,
    rented,
  }
}

const formatSeason = ({
  season,
  t,
  adjustSeason,
  adjustEpisode,
}: {
  season: TvShowSeasonWithEpisodes
  t: TFunction
  adjustSeason: AdjustPurchaseableFn
  adjustEpisode: AdjustPurchaseableFn
}) => {
  const adjustedSeason = adjustSeason(season)
  const { labelPrice, rented } =
    extendMediaItemByLabelPriceTvShowSeason(adjustedSeason)
  const { episodes, episodesAmount } = adjustedSeason

  return {
    ...adjustedSeason,
    title: t('episode_count', {
      count: episodesAmount,
    }),
    labelPrice,
    rented,
    episodes: episodes.map((episode) =>
      formatSeasonEpisode({ episode, t, adjustEpisode })
    ),
  }
}

export const playlistFormatter = ({
  playlist,
  t,
  adjustEpisode,
  adjustSeason,
}: {
  playlist: TVShowsPlaylist
  t: TFunction
  adjustEpisode: AdjustPurchaseableFn
  adjustSeason: AdjustPurchaseableFn
}) => {
  if (playlist.seasonsWithEpisodes) {
    return {
      ...playlist,
      seasonsWithEpisodes: playlist?.seasonsWithEpisodes?.map((season) =>
        formatSeason({ season, t, adjustSeason, adjustEpisode })
      ),
    }
  }

  if (playlist.episodes) {
    return {
      ...playlist,
      episodes: playlist?.episodes?.map((episode) =>
        formatSeasonEpisode({ episode, t, adjustEpisode })
      ),
    }
  }

  return playlist
}
