import type { IRewindMediaUrlParams } from '$/player/event/index.h'
import type { IWithRewoundMediaUrl } from '@/interfaces/mediaUrl'
import { api, type LiveEvent, type MediaUrl } from '@setplex/tria-api'
import {
  attach,
  createEffect,
  createEvent,
  createStore,
  restore,
  sample,
} from 'effector'

export const loadLiveEventFx = attach({
  effect: api.liveEvents.getLiveEventByIdFx,
})

export const $currentEvent = restore(loadLiveEventFx.doneData, null)

// get live media url
export const getEventMediaUrl = createEvent()

// get rewound media url
export const getRewoundEventMediaUrl = createEvent<{
  urlStartTime?: number
  targetStartTime?: number
  rewindType?: string
}>()

const loadLiveEventUrlSyncedFx = createEffect(
  async ({
    event,
  }: {
    event?: LiveEvent | null
  }): Promise<IWithRewoundMediaUrl | null> => {
    if (!event) return null

    const rewindUrl: MediaUrl | null = await api.player.loadLiveEventUrlFx({
      eventId: event.id,
    })

    if (!rewindUrl?.playbackUrl) return null
    return {
      ...rewindUrl,
      rewoundUrlStartSec: 0,
      rewoundTargetStartSec: 0,
    }
  }
)

const loadRewoundLiveEventUrlSyncedFx = createEffect(
  async ({
    event,
    urlStartTime,
    targetStartTime,
    rewindType,
  }: IRewindMediaUrlParams): Promise<IWithRewoundMediaUrl | null> => {
    if (!targetStartTime || !urlStartTime || !event) return null

    const rewindUrl: MediaUrl | null = await api.player.loadLiveEventUrlFx({
      eventId: event.id,
      startTime: urlStartTime,
      rewindType,
    })

    if (!rewindUrl?.playbackUrl) return null
    return {
      ...rewindUrl,
      rewoundUrlStartSec: urlStartTime,
      rewoundTargetStartSec: targetStartTime,
    }
  }
)

sample({
  clock: getEventMediaUrl,
  source: $currentEvent,
  fn: (event) => ({ event: event }),
  target: loadLiveEventUrlSyncedFx,
})

sample({
  clock: getRewoundEventMediaUrl,
  source: $currentEvent,
  fn: (event, clock) => ({ event: event, ...clock }),
  target: loadRewoundLiveEventUrlSyncedFx,
})

export const $mediaUrlData = createStore<IWithRewoundMediaUrl | null>(null)
  .on(
    [
      loadLiveEventUrlSyncedFx.doneData,
      loadRewoundLiveEventUrlSyncedFx.doneData,
    ],
    (_, payload) => payload
  )
  .reset($currentEvent)
