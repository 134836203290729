import { attach, createEffect, createStore } from 'effector'
import type { ApiEffect, Page, PageableInfo } from '../index.h'
import type { MediaUrl } from '../interfaces/generic'
import {
  EventStatus,
  type GetLiveEventsParams,
  type GetPurchasedLiveEventsParams,
  type LiveEvent,
} from '../interfaces/live-events'

// GET /v3/live-events
export const getLiveEventsFx: ApiEffect<
  GetLiveEventsParams,
  Page<LiveEvent>
> = createEffect()

// GET /v3/library/live-events
export const getPurchasedLiveEventsFx: ApiEffect<
  GetPurchasedLiveEventsParams,
  Page<LiveEvent>
> = createEffect()

export const getPurchasedLiveEventsRowFx = attach({
  effect: getPurchasedLiveEventsFx,
  mapParams: (): GetPurchasedLiveEventsParams => ({
    myList: 'myList',
  }),
})

export const getPurchasedLiveEventsGridFx = attach({
  effect: getPurchasedLiveEventsFx,
  mapParams: (
    params?: GetPurchasedLiveEventsParams
  ): GetPurchasedLiveEventsParams => ({
    myList: 'myList',
    ...params,
  }),
})

export const initLiveEventsFx = attach({
  effect: getLiveEventsFx,
  mapParams: (params) => ({ ...params, status: EventStatus.LIVE, page: 0 }),
})
export const loadLiveEventsFx = attach({
  effect: getLiveEventsFx,
  mapParams: (params) => ({ ...params, status: EventStatus.LIVE }),
})

export const initLiveUpcomingEventsFx = attach({
  effect: getLiveEventsFx,
  mapParams: (params) => ({
    ...params,
    status: EventStatus.LIVE_SOON,
    page: 0,
  }),
})

export const loadLiveSoonEventsFx = attach({
  effect: getLiveEventsFx,
  mapParams: (params) => ({ ...params, status: EventStatus.LIVE_SOON }),
})
export const initPastEventsFx = attach({
  effect: getLiveEventsFx,
  mapParams: (params) => ({
    ...params,
    status: EventStatus.ENDED,
    page: 0,
  }),
})
export const loadPastEventsFx = attach({
  effect: getLiveEventsFx,
  mapParams: (params) => ({
    ...params,
    status: EventStatus.ENDED,
  }),
})
export const initUpcomingEventsFx = attach({
  effect: getLiveEventsFx,
  mapParams: (params) => ({ ...params, status: EventStatus.SOON, page: 0 }),
})
export const loadUpcomingEventsFx = attach({
  effect: getLiveEventsFx,
  mapParams: (params) => ({ ...params, status: EventStatus.SOON }),
})

// GET /v3/live-events/{eventId}
export const getLiveEventByIdFx: ApiEffect<{ eventId: number }, LiveEvent> =
  createEffect()

// GET /v3/live-events/{eventId}/url
export const getLiveEventUrlByIdFx: ApiEffect<
  { eventId: number; startTime?: number; rewindType?: string },
  MediaUrl
> = createEffect()

/*
 * Stores
 */
export const $liveEvents = createStore<LiveEvent[]>([])
  .on(initLiveEventsFx.doneData, (_, { content }) => content || [])
  .on(loadLiveEventsFx.doneData, (state, { content }) => [
    ...state,
    ...(content || []),
  ])

export const $liveEventsPageInfo = createStore<PageableInfo | null>(null).on(
  [initLiveEventsFx.done, loadLiveEventsFx.done, loadLiveSoonEventsFx.done], // TODO without adding load effects, pagination does not work for queries on event pages and event search
  (_, { result: { content: _ignored, ...pageInfo }, params }) => ({
    ...pageInfo,
    params,
  })
)

export const $liveUpcominEvents = createStore<LiveEvent[]>([]).on(
  initLiveUpcomingEventsFx.doneData,
  (_, { content }) => content || []
)

export const $liveUpcomingEventsPageInfo = createStore<PageableInfo | null>(
  null
).on(
  [initLiveUpcomingEventsFx.done],
  (_, { result: { content: _ignored, ...pageInfo }, params }) => ({
    ...pageInfo,
    params,
  })
)

export const $upcomingEvents = createStore<LiveEvent[]>([])
  .on(initUpcomingEventsFx.doneData, (_, { content }) => content || [])
  .on(loadUpcomingEventsFx.doneData, (state, { content }) => [
    ...state,
    ...(content || []),
  ])
export const $upcomingEventsPageInfo = createStore<PageableInfo | null>(
  null
).on(
  [initUpcomingEventsFx.done, loadUpcomingEventsFx.done],
  (_, { result: { content: _ignored, ...pageInfo }, params }) => ({
    ...pageInfo,
    params,
  })
)

export const $pastEvents = createStore<LiveEvent[]>([])
  .on(initPastEventsFx.doneData, (_, { content }) => content || [])
  .on(loadPastEventsFx.doneData, (state, { content }) => [
    ...state,
    ...(content || []),
  ])
export const $pastEventsPageInfo = createStore<PageableInfo | null>(null).on(
  [initPastEventsFx.done, loadPastEventsFx.done],
  (_, { result: { content: _ignored, ...pageInfo }, params }) => ({
    ...pageInfo,
    params,
  })
)

export const $liveEvent = createStore<LiveEvent | null>(null).on(
  getLiveEventByIdFx.doneData,
  (_, event) => event || null
)
