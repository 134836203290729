import type { TExternalError } from '@setplex/pureya'
import { api } from '@setplex/tria-api'
import { type Store } from 'effector'
import { or } from 'patronum'

export { play, stop, watchingContent } from './analytics'

export const $isUrlRequestPending: Store<boolean> = or(
  api.player.loadVodUrl.pending,
  api.player.loadChannelUrlFx.pending,
  api.player.loadEpisodeUrlPlayer.pending,
  api.player.loadShowTrailerUrl.pending,
  api.player.loadVodTrailerUrl.pending,
  api.player.loadLiveEventUrlFx.pending,
  api.player.loadCatchupUrlFx.pending
)

export const $currentPlayerPageUrlErrorFormat: Store<TExternalError | null> =
  api.player.$currentPlayerPageUrlError.map((store) =>
    store
      ? {
          code: store.errorCode || store.code,
          message: store.message,
        }
      : null
  )
