import { model as i18n, type TFunction } from '!/i18n'
import { addLanguage, mapError, type ExternalError } from '@setplex/pureya'
import { attach, createEvent, sample } from 'effector'
import * as error from './error-map'

export const init = createEvent()

/**
 * i18n keys, used in player
 */
const KEYS: (string | [string, string])[] = [
  // TODO: should all be mapped to native videojs strings
  'live', // 'Live'
  'description', // 'Description'
  'directors', // 'Directors'
  'stars', // 'Stars'
  'off', // 'OFF'
  'quality', // 'Quality'
  'quality_auto', // 'Auto'
  'default', // 'Default'
  'casting_status', // 'Casting to {{device}} is'
  'casting_activated', // 'activated'
  'please_tap_button', // 'Please tap button'
  'stop_casting_message', // 'or use notification panel to stop casting'
  'locked_channel_title', // 'Locked Channel'

  // 'something_went_wrong_error', // 'Something went wrong'
  // 'protected_content_error', // 'We are having problem playing protected (DRM) content on your device.'
  // 'select_different_item_error', // 'Please select a different item.'
  // 'try_browser_or_update_error', // 'Please try a different browser or update the version of the current one.'

  // translate native videojs strings
  ['Audio', 'button_audio'], // 'Audio'
  ['Subtitles', 'subtitles'], // 'Subtitles'
  ['Settings', 'settings'], // 'Settings'
  ['Error', 'playback_error'], // 'Playback Error' //??? should be just simple 'Error' maybe?
  ['Error Code', 'error_code'], // 'Error Code'
]

// prettier-ignore
mapError(addKeys([
  [error.AE402, {
    title: 'drm_error_title', // 'Content Access Error'
    message: 'payment_required_error', // 'Payment required.'
  }],
  [error.AE403, {
    title: 'drm_error_title', // 'Content Access Error'
    message: 'sorry_item_not_available', // 'Sorry, {{title}} is not available.'
  }],
  [error.DR001, {
    title: 'drm_error_title', // 'Content Access Error'
    message: 'drm_error_text', // 'There is a problem playing protected (DRM) content on your device.'
  }],
  [error.GE001, {
    title: 'geoblock_error_title', // 'Geoblock Error'
    message: 'content_geoblock', // 'Sorry, {{title}} is not available in your region.'
  }],
  [error.GE002, {
    title: 'geoblock_error_title', // 'Geoblock Error'
    message: 'content_geoblock', // 'Sorry, {{title}} is not available in your region.'
  }],
  [error.NE001, {
    title: 'network_error_title', // 'Network Error'
    message: 'network_error_message', // 'An error occurred while loading this content. Please check your network connection and try again.'
  }],
  [error.PE003, {
    title: 'playback_error', // 'Playback Error'
    message: 'refresh_or_select_error', // 'Something went wrong. Please try to refresh the page or select a different item.'
  }],
  [error.PE004, {
    title: 'playback_error', // 'Playback Error'
    message: 'loading_content_error_text', // 'An error occurred while loading this content. Try refreshing the page or coming back a little later.'
  }],
  [error.PE005, {
    title: 'playback_error', // 'Playback Error'
    message: 'browser_recognize_error_message', // 'Your browser does not currently recognize any of the video formats available. Try again later, or select a different item.'
  }],
  [error.PE006, {
    title: 'playback_error', // 'Playback Error'
    message: 'cannot_play_video_error', // 'Cannot play video. Try again later, or select a different item.'
  }],
  // [error.PE008, {
  //   title: 'playback_error', // 'Playback Error'
  //   message: 'playing_media_error', // 'We are having issues playing this video. Please try to refresh the page or select a different item.'
  // }],
  [error.PE010, {
    title: 'playback_error', // 'Playback Error'
    message: 'stream_or_network_issues_error', // 'An error occurred while loading this content. This could be due to a stream issue or network problems. Please try again later.'
  }],
  [error.SE001, {
    title: 'server_error', // 'Server Error'
    message: 'stream_or_network_issues_error', // 'An error occurred while loading this content. This could be due to a stream issue or network problems. Please try again later.'
  }],
  [error.SE404, {
    title: 'server_error', // 'Server Error'
    message: 'stream_or_network_issues_error', // 'An error occurred while loading this content. This could be due to a stream issue or network problems. Please try again later.'
  }],
  [error.SE500, {
    title: 'server_error', // 'Server Error'
    message: 'stream_or_network_issues_error', // 'An error occurred while loading this content. This could be due to a stream issue or network problems. Please try again later.'
  }]
]))

const addLanguageFx = attach({
  source: i18n.$t,
  effect({ t }, locale: string) {
    addLanguage(locale, translate(t))
  },
})

sample({
  clock: [i18n.$locale, i18n.$ready, init],
  source: i18n.$locale,
  target: addLanguageFx,
})

//
// Helpers functions
//

/**
 * Goes through the map of errors and adds all new keys to the KEYS array
 */
function addKeys(map: [number | string | symbol, ExternalError][]) {
  // collect new keys from errors map
  const keys = new Set<string>()
  for (const [_, mapped] of map) {
    if (mapped != null && typeof mapped === 'object') {
      if (mapped.title) keys.add(mapped.title)
      if (mapped.message) keys.add(mapped.message)
    }
  }

  // append new keys to `translations` array
  Array.prototype.push.apply(KEYS, Array.from(keys))

  // return given map without changes
  return map
}

/**
 * Goes through the KEYS array and translates all keys
 */
function translate(t: TFunction): Record<string, string> {
  const translations: Record<string, string> = {}

  // translate all the keys
  for (const k of KEYS) {
    let key: string, value: string
    if (typeof k === 'string') {
      key = k
      value = t(k)
    } else {
      key = k[0]
      value = t(k[1])
    }

    // convert "named" placeholders to "positional" placeholders, used by videojs localization
    const placeholders: string[] = []
    value = value.replace(/{{([^}]+)}}/g, (_, p1) => {
      const index = placeholders.indexOf(p1)
      if (index === -1) {
        placeholders.push(p1)
        return `{${placeholders.length}}`
      }
      return `{${index + 1}}`
    })

    // add to translations
    translations[key] = value
  }

  return translations
}
