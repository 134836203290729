import { Logger } from '@/logger'
import {
  setPlayerDebugMode,
  setLogger as setPlayerLogger,
} from '@setplex/pureya'
import { api } from '@setplex/tria-api'
import {
  adapter,
  setLogger as setAdapterLogger,
} from '@setplex/tria-api-adapter-nora'
import { env } from '~/shared/config'
import { deviceModel } from '../device'
import { request } from './request'

api.use(adapter({ $deviceType: deviceModel.$deviceType }))
api.fx.use(request)

// set logger for a adapter
if (process.env.NODE_ENV === 'development' || env.TRIA_CLIENT_ADAPTER_DEBUG) {
  setAdapterLogger(new Logger('adapter'))
}

// set debug mode for player errors
if (env.TRIA_CLIENT_PLAYER_DEBUG) {
  setPlayerDebugMode(env.TRIA_CLIENT_PLAYER_DEBUG)
}

if (process.env.NODE_ENV === 'development' || env.TRIA_CLIENT_PLAYER_DEBUG) {
  setPlayerLogger(new Logger('player'))
}
