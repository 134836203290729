import { mapError, MediaError } from '@setplex/pureya'
import { createEvent } from 'effector'

export const AE402 = Symbol('AE402')
export const AE403 = Symbol('AE403')
export const DR001 = Symbol('DR001')
export const GE001 = Symbol('GE001')
export const GE002 = Symbol('GE002')
export const NE001 = Symbol('NE001')
export const PE003 = Symbol('PE003')
export const PE004 = Symbol('PE004')
export const PE005 = Symbol('PE005')
export const PE006 = Symbol('PE006')
export const PE010 = Symbol('PE010')
export const SE001 = Symbol('SE001')
export const SE404 = Symbol('SE404')
export const SE500 = Symbol('SE500')

const HttpErrors = {
  BAD_REQUEST: 400,
  PAYMENT_REQUIRED: 402,
  ACCESS_ERROR: 403,
  NOT_FOUND: 404,
  TOO_MANY_REQUESTS: 429,
  UNAVAILABLE_FOR_LEGAL_REASONS: 451,
  API_ERROR: 500,

  // failed request codes
  REQUEST_UNKNOWN_ERROR: -1000,
  REQUEST_CANCELLED_ERROR: -1001,
  REQUEST_TIMEOUT_ERROR: -1002,
  REQUEST_FAILED_ERROR: -1003,
}

export const init = createEvent()

mapError([
  // native errors
  [MediaError.MEDIA_ERR_CUSTOM, PE003],
  [MediaError.MEDIA_ERR_ABORTED, PE004],
  [MediaError.MEDIA_ERR_NETWORK, NE001],
  [MediaError.MEDIA_ERR_DECODE, PE005],
  [MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED, PE006],
  [MediaError.MEDIA_ERR_ENCRYPTED, DR001],

  // http errors
  [HttpErrors.BAD_REQUEST, SE001],
  [HttpErrors.NOT_FOUND, SE404],
  [HttpErrors.TOO_MANY_REQUESTS, SE001],
  [HttpErrors.PAYMENT_REQUIRED, AE402],
  [HttpErrors.ACCESS_ERROR, AE403],
  [HttpErrors.UNAVAILABLE_FOR_LEGAL_REASONS, GE002],
  [HttpErrors.API_ERROR, SE500],

  // failed request codes
  [HttpErrors.REQUEST_UNKNOWN_ERROR, SE001],
  [HttpErrors.REQUEST_CANCELLED_ERROR, SE001],
  [HttpErrors.REQUEST_TIMEOUT_ERROR, PE010],
  [HttpErrors.REQUEST_FAILED_ERROR, SE001],

  // wbs errors
  // TODO: move WBS codes to '@setplex/tria-api-adapter-nora' package
  ['WB0051', GE001],
  ['WB0052', GE001],
  ['WG0062', GE001],
  ['WG0400', SE001],
  ['WG0429', SE001],
  ['WG0500', SE500],
])

/*
MEDIA_ERR_UNKNOWN, 'PE002'
PLAYER_ERR_NO_SRC, 'PE007'
TECH_ERROR, 'PE008'
..., 'PE009'
PLAYER_ERR_TIMEOUT, 'PE010'
..., 'PE011'
DRM_UNSUPPORTED_TYPE, 'DR002'
PLAYER_API_SERVER_ERROR, 'SE001'
*/
