import { t } from '!/i18n'
import {
  ONE_DAY_MS,
  ONE_HOUR_MS,
  ONE_MINUTE_MS,
  ONE_SECOND_MS,
} from '@/constants/time'
import {
  add,
  differenceInDays,
  format,
  formatDistanceToNowStrict,
  formatDuration,
  type Duration,
  type DurationUnit,
} from 'date-fns'
import { HAS_ISO8601_TIMEZONE_RE } from '../constants/regularExp'
import { formatTimeString } from './strings'

// Date format:
export enum DATE_FORMATS {
  // 08:15
  HH_MM = 'hh:mm',
  // 8:15 AM/PM localized
  HH_MM_LOCALIZED = 'p',
  // 25 Jan
  DD_MMM = 'dd MMM',
  // 2024
  YYYY = 'yyyy',
  // 2024-01-26
  YYYY_MM_DD = 'yyyy-MM-dd',
  // 11-29-17
  HH_MM_SS = 'HH-mm-ss',
  // 11:29:17
  'HH:MM:SS' = 'HH:mm:ss',
  // Apr 29, 1453, 12:00 AM/PM localized
  MMM_DD_YYYY_HH_MM_LOCALIZED = 'PPp',
  // 24 Jan 12:00 AM/PM localized
  DD_MMM_HH_MM_LOCALIZED = 'dd MMM p',
  // Apr 29, 1453 localized
  MMM_DD_YYYY_LOCALIZED = 'PP',
  // Monday, 24 Jan
  EEEE_DD_MM = 'EEEE, dd MMM',
}
interface FormatDateDurationParams {
  duration: Duration
  format?: DurationUnit[]
  short?: boolean
}
// '2 years 9 months 1 week 7 days 5 hours 9 minutes 30 seconds'
// short: '2y 9m 1w 7d 5h 9m 30s'
export const formatDateDuration = ({
  duration,
  format,
  short,
}: FormatDateDurationParams) => {
  const durationString = formatDuration(duration, { format })
  const shortDurationString = formatTimeString(durationString)

  return short ? shortDurationString : durationString
}

interface FormatDateDistanceToNowParams {
  minutes: number
  hours: number
}
// in 1 hour | in 7 minutes | in 1 hour 7 minutes
export const formatDateDistanceToNow = ({
  minutes,
  hours,
}: FormatDateDistanceToNowParams) => {
  const currentDate = new Date()
  const untilDateMinutes = add(currentDate, {
    minutes,
  })
  const untilDateHours = add(currentDate, {
    hours,
  })
  const distanceHoursSuffix = formatDistanceToNowStrict(untilDateHours, {
    addSuffix: true,
  })

  const distanceMinutes = formatDistanceToNowStrict(untilDateMinutes)
  const distanceMinutesSuffix = formatDistanceToNowStrict(untilDateMinutes, {
    addSuffix: true,
  })

  if (hours && !minutes) {
    return distanceHoursSuffix
  }

  if (!hours && minutes) {
    return distanceMinutesSuffix
  }

  return `${distanceHoursSuffix} ${distanceMinutes}`
}

// in 1 hour | in 7 minutes | in 1 hour 7 minutes | in 1 hour 7 minutes 10 seconds
export const formatSecondsDistanceToNow = ({
  minutes,
  hours,
}: FormatDateDistanceToNowParams) => {
  const currentDate = new Date()
  const untilDateMinutes = add(currentDate, {
    minutes,
  })
  const untilDateHours = add(currentDate, {
    hours,
  })
  const distanceHoursSuffix = formatDistanceToNowStrict(untilDateHours, {
    addSuffix: true,
  })

  const distanceMinutes = formatDistanceToNowStrict(untilDateMinutes)
  const distanceMinutesSuffix = formatDistanceToNowStrict(untilDateMinutes, {
    addSuffix: true,
  })

  if (hours && !minutes) {
    return distanceHoursSuffix
  }

  if (!hours && minutes) {
    return distanceMinutesSuffix
  }

  return `${distanceHoursSuffix} ${distanceMinutes}`
}

export const asDate = (date: number | string | Date): Date => {
  return typeof date === 'number' || typeof date === 'string'
    ? new Date(date)
    : date
}

const asYear = (date: number | Date): Date => {
  return typeof date === 'number' ? new Date(date, 0) : date
}

export const formatDateToLabelInfo = (
  startMs: number | Date,
  stopMs: number | Date
): string => {
  const startDate = format(startMs, DATE_FORMATS.HH_MM_LOCALIZED)
  const endDate = format(stopMs, DATE_FORMATS.HH_MM_LOCALIZED)
  return t('program-start-end-time', { lng: 'date', startDate, endDate })
}

export const formatDateTimeMonthShort = (
  start: Date | string | number,
  end: Date | string | number
): string => {
  const dateOptions = {
    startDate: format(asDate(start), DATE_FORMATS.HH_MM_LOCALIZED),
    endDate: format(asDate(end), DATE_FORMATS.HH_MM_LOCALIZED),
    date: format(asDate(start), DATE_FORMATS.DD_MMM),
  }

  return t('date-time-month-short', { ...dateOptions, lng: 'date' })
}

export const formatYearToLocaleString = (year: number): string => {
  const date = format(asYear(year), DATE_FORMATS.YYYY)
  return t('date-formatted', { lng: 'date', date })
}

export const formatStartTime = (
  time: string | Date | number,
  formatTemplate: string
): string => {
  const date = format(asDate(time), formatTemplate)
  return t('date-formatted', { lng: 'date', date })
}
/**
 * Function formats date according next rules:
 * 0 < N <= 24 – 1 day
 * 24 < N <= 48 – 2 days
 * @param remainingTimeInMillis
 */
export const getFormattedDate = (remainingTimeInMillis: number) => {
  // +1 because full and partial days are needed. Cant use here formatDistanceStrict, because sometime days > 29
  const days = differenceInDays(new Date(remainingTimeInMillis), new Date()) + 1

  return t('day_count', { count: days })
}

// This helper is needed only for tests running on server side
// Related with NARROW NO-BREAK SPACE (U+202f) issue in Node (https://github.com/nodejs/node/issues?q=is%3Aissue+U%2B202f+is%3Aclosed)
export const formatDateAnswer = (string: string): string =>
  string.replace(/[\u202F\u00A0]/, ' ')

/**
 * Set seconds to 0 in date or timestamp (`now` by default)
 */
export const zeroizeSeconds = (from: Date | number = Date.now()): number =>
  Math.trunc(Number(from) / ONE_MINUTE_MS) * ONE_MINUTE_MS

/**
 * Calculate time units within two dates or timestamps, or single time range
 * used for formatting time left
 */
export const timeWithin = (from: Date | number, to?: Date | number) => {
  const diff = Math.abs(to == null ? Number(from) : Number(to) - Number(from))
  const days = Math.floor(diff / ONE_DAY_MS)
  const hours = Math.floor((diff % ONE_DAY_MS) / ONE_HOUR_MS)
  const minutes = Math.floor((diff % ONE_HOUR_MS) / ONE_MINUTE_MS)
  const seconds = Math.floor((diff % ONE_MINUTE_MS) / ONE_SECOND_MS)
  return { days, hours, minutes, seconds }
}

export const formatExpirationDate = (expirationDate?: string) => {
  if (!expirationDate) return ''

  const expirationTimeZ = hasISO8601TimezoneDefined(expirationDate)
    ? expirationDate
    : `${expirationDate}Z`

  return format(new Date(expirationTimeZ), DATE_FORMATS.MMM_DD_YYYY_LOCALIZED)
}

export function hasISO8601TimezoneDefined(date: string): boolean {
  return HAS_ISO8601_TIMEZONE_RE.test(date)
}
