import { model as router } from '!/router'
import { model as config, remote } from '@/config'
import { postpone } from '@/effector'
import { T } from '@/helpers'
import { logger } from '@/logger'
import { addScriptAsync } from '@/tools/script'
import { name, version } from '@setplex/pureya'
import {
  attach,
  createEffect,
  createEvent,
  createStore,
  restore,
  sample,
  type Store,
} from 'effector'
import { not } from 'patronum'
import { model as session } from '~/entities/session'
import { previousLocationModel } from '~/shared/effector/previousLocation'
import { $guest } from '../subscriber/guest/model'
import { getDescriptionUrl, setUrlMacrosToParams } from './helpers'
import { type Goog, type NonceRequest } from './index.h'

declare const goog: Goog

export const init = createEvent()

const awaited = createEvent()

export const $googlePalEnabled: Store<boolean> = config.get(
  remote.tria_isGooglePalEnabled
)
export const $googlePalReady = createStore<boolean>(false)

// Google PAL SDK script loader
// NB: this script may be blocked by adblockers!
const loadGooglePalSdkFx = createEffect(async () => {
  await addScriptAsync('//imasdk.googleapis.com/pal/sdkloader/pal.js')
  if (!('goog' in window && window.goog != null && goog.pal != null)) {
    throw new Error('`goog.pal` is not defined')
  }
})

// nonce loader
const createNonceLoaderFx = createEffect(() => {
  const consentSettings = new goog.pal.ConsentSettings()
  return new goog.pal.NonceLoader(consentSettings)
})

const $nonceLoader = restore(createNonceLoaderFx.doneData, null)

// nonce manager
const loadNonceManagerFx = attach({
  source: {
    loader: $nonceLoader,
    session: session.$session,
    location: router.$location,
    guestMode: $guest,
    fromUrl: previousLocationModel.$fromComeHref,
  },
  effect: (
    { loader, session, location, fromUrl },
    params: Partial<NonceRequest>
  ) => {
    if (!loader) return null
    const uniqueUserId = session?.guestId
      ? session.guestId
      : String(session?.profileId)

    // https://docs.google.com/spreadsheets/d/1pfWja03bUAxhaulNJaIvZ_nBM2-TuBl6uQk9HHeNLBE/edit#gid=0
    const request = new goog.pal.NonceRequest()
    request.adWillAutoPlay = true
    request.adWillPlayMuted = false
    request.continuousPlayback = true
    request.descriptionUrl = getDescriptionUrl(fromUrl, location?.pathname)
    request.url = window.location.href
    request.iconsSupported = true
    request.omidVersion = '1.0.0'
    request.omidPartnerVersion = '1.2.3'
    request.omidPartnerName = 'aniview'
    request.playerType = name
    request.playerVersion = version
    request.ppid = uniqueUserId
    // sessionId is taken from params, because it must be contstant
    // when open the player and when navigating via player.
    // Sended here from  player(catchup, event, show, tv, vod) ui
    request.supportedApiFrameworks = [] // will add 7 nonetheless, because of `omid...` params

    Object.assign(request, params)

    logger.info('nonce manager request:', request)
    return loader.loadNonceManager(request)
  },
})

export const $nonceManager = restore(loadNonceManagerFx.doneData, null)

// generate nonce
const getNonceFx = attach({
  source: $nonceManager,
  effect: (manager) => {
    if (manager) return manager.getNonce()
    throw new Error('nonce manager is not loaded')
  },
})

//
// init, load sdk, create nonce loader
//

postpone({
  clock: init,
  until: $googlePalEnabled,
  target: awaited,
})

// when enabled -> load (or not) google pal sdk
sample({
  clock: awaited,
  filter: not($googlePalReady), // do not load script twice
  target: loadGooglePalSdkFx,
})

sample({
  clock: loadGooglePalSdkFx.failData,
  fn: (error) => ['failed to load Google PAL SDK:', error],
  target: logger.errorFx,
})

// when google pal sdk is loaded -> create nonce loader
sample({
  clock: loadGooglePalSdkFx.done,
  fn: T,
  target: [$googlePalReady, createNonceLoaderFx],
})

sample({
  clock: createNonceLoaderFx.failData,
  fn: (error) => ['failed to create Google PAL nonce loader:', error],
  target: logger.errorFx,
})

//
// generate nonce
//

export const generateNonceFx = createEffect(
  // do not pass `url`, it is taken from current page location
  async ({ url, ...params }: Partial<NonceRequest> = {}) => {
    await loadNonceManagerFx(params)
    return await getNonceFx()
  }
)

//
// modify url
//

export const setPlaybackUrl = createEvent<{
  url: string
  sessionId: string
  videoHeight?: number
  videoWidth?: number
}>()

export const $playbackUrl = createStore<string>('') //
  .on(setPlaybackUrl, (_, { url }) => url)
export const $modifiedUrl = createStore<string>('') //
  .on(setPlaybackUrl, () => '')

export const modifyUrlFx = createEffect(setUrlMacrosToParams)

sample({
  clock: setPlaybackUrl,
  source: $googlePalReady,
  filter: (googlePalReady, { url }) => googlePalReady && url !== '',
  fn: (_, params) => params,
  target: generateNonceFx,
})

sample({
  clock: setPlaybackUrl,
  source: $googlePalReady,
  filter: (googlePalReady, { url }) => !googlePalReady && url !== '',
  fn: (_, params) => params,
  target: modifyUrlFx,
})

sample({
  clock: generateNonceFx.finally,
  source: $playbackUrl,
  filter: (url, { params }) => params != null && params.url === url,
  fn: (url, result) => ({
    url,
    nonce: result.status === 'done' ? result.result : undefined,
  }),
  target: modifyUrlFx,
})

sample({
  clock: generateNonceFx.doneData,
  fn: (nonce) => ['generated nonce:', nonce],
  target: logger.infoFx,
})

sample({
  clock: generateNonceFx.failData,
  fn: (error) => ['failed to generate nonce:', error],
  target: logger.errorFx,
})

sample({
  clock: modifyUrlFx.finally,
  source: $playbackUrl,
  filter: (url, { params }) => params != null && params.url === url,
  fn: (url, result) => (result.status === 'done' ? result.result : url),
  target: $modifiedUrl,
})
